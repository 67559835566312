<template>
  <Header title="Daftar Akun" link1="Akuntansi" link2="Daftar Akun"></Header>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <h5 class="text-secondary d-inline">Daftar Kode Akun</h5>
            </div>
            <div class="col-md-8">
              <div class="float-end">
                <button class="btn btn-circle btn-gradient" @click="add()"> <ion-icon name="add-circle-outline"></ion-icon> Tambah Akun</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="float-end">
            <div class="input-group mb-3">
              <input type="text" v-model="search" class="form-control" placeholder="Pencarian" aria-label="Pencarian">
              <span class="input-group-text" id="basic-addon2"> <ion-icon name="search"></ion-icon> </span>
            </div>
          </div>
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th>No</th>
                <th>Akun Induk</th>
                <th>Kode Akun</th>
                <th>Nama Akun</th>
                <th>Status</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in filteredList" :key="account.id">
                <td>{{ index+1 }}</td>
                <td>{{ account.parent_id }}</td>
                <td>{{ account.id }}</td>
                <td>{{ account.name }}</td>
                <td><span v-if="account.status == 1" class="label label-success fw-bold">Aktif</span></td>
                <td>
                  <a class="btn btn-sm btn-link-gray" href="#" @click="edit(index, 'active')">Edit <ion-icon name="create-outline"></ion-icon></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Tambah Kode Akun</h5>
                <button type="button" class="btn-close" @click="showModal = false"></button>
              </div>
              <div class="modal-body">
                <form class="row g-3">
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Akun Induk</label>
                    <!-- <input type="text" class="form-control" v-model="data.parent_id" required> -->
                    <select class="form-select" v-model="data.parent_id">
                      <option value="">Tanpa Induk</option>
                      <option
                        v-for="acc in accounts"
                        :key="acc.id"
                        :label="acc.id+' - '+acc.name"
                        :value="acc.id">
                      </option>
                    </select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Kode Akun</label>
                    <input type="text" class="form-control" v-model="data.id" :readonly="state == 'edit'" required>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Nama Akun</label>
                    <input type="text" class="form-control" v-model="data.name" required>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Tipe Akun</label>
                    <select class="form-select" v-model="data.acc_account_type_id" required>
                      <option value="">-- Pilih Tipe --</option>
                      <option
                        v-for="type in account_types"
                        :key="type.id"
                        :label="type.name"
                        :value="type.id">
                      </option>
                    </select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Keterangan</label>
                    <textarea class="form-control text-secondary" rows="2" v-model="data.description"></textarea>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Status</label>
                    <div class="form-checkbox">
                      <input class="form-check-input" type="checkbox" v-model="data.status">
                      <span> Aktif</span>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" @click="showModal = false"> <ion-icon name="close-outline"></ion-icon> Tutup</button>
                <button type="button" class="btn btn-primary" @click="save()"> <ion-icon name="save-outline"></ion-icon> Simpan</button>
                <!-- <button type="button" class="btn btn-success"> <ion-icon name="color-wand-outline"></ion-icon> Simpan dan Generate</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script language="ts" src="./scripts/account.ts"></script>

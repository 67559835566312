import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import swal from 'sweetalert'
import { accountFactory } from '@/http/account'

@Options({
  components: {
    Header
  },
  data() {
    return {
      accounts: [],
      account_types: [],
      totalBill: 0,
      search: '',
      data: {
        id: '',
        parent_id: '',
        acc_account_type_id: '',
        name: '',
        description: '',
        status: true
      },
      showModal: false,
      state: ''
    }
  },
  methods: {
    save: function() {
      if (this.data.name == '') {
        swal("Nama akun tidak boleh kosong", "", "warning")
        return
      }
      if (this.state == 'add') {
        // this.data.status = (this.data.status) ? 1 : 0,
        Promise.all([
          accountFactory.create(this.data),
        ]).then(results => {
          if (results[0] != false) {
            swal("Sukses menyimpan akun", "", "success")
            this.showModal = false
            this.reload()
          } else {
            swal("Gagal menyimpan akun", "", "error")
          }
        }).catch((e) => {
          swal("Gagal menyimpan akun", "", "error")
          console.log(e)
        })
      } else {
        var data = {
          name: this.data.name,
          parent_id: this.data.parent_id,
          acc_account_type_id: this.data.acc_account_type_id,
          description: this.data.description,
          status: (this.data.status) ? 1 : 0
        }
        Promise.all([
          accountFactory.update(this.data.id, data),
        ]).then(results => {
          if (results[0] != false) {
            swal("Sukses menyimpan akun", "", "success")
            this.showModal = false
            this.reload()
          } else {
            swal("Gagal menyimpan akun", "", "error")
          }
        }).catch((e) => {
          swal("Gagal menyimpan akun", "", "error")
          console.log(e)
        })
      }
    },
    edit: function(index: any, type: any) {
      // open modal with selected data
      this.showModal = true
      var account = this.accounts[index]
      var data = {
        id: account.id,
        parent_id: account.parent_id,
        acc_account_type_id: account.acc_account_type_id,
        name: account.name,
        description: account.description,
        status: (account.status == 1) ? true : false,
      }
      this.data = data
      this.state = 'edit'
    },
    add: function() {
      this.showModal = true
      this.data.id = ''
      this.data.acc_account_type_id = ''
      this.data.parent_id = ''
      this.data.name = ''
      this.data.description = ''
      this.data.status = true
      this.state = 'add'
    },
    reload: function() {
      Promise.all([
        accountFactory.get(),
      ]).then(results => {
        this.accounts = results[0].data.accounts
        this.account_types = results[0].data.account_types
      })
    },
    generateStringID: function() {
      var name = this.data.name
      var string_id = name.replace(/ /g,"_")
      this.data.id = string_id.toLowerCase()
    }
  },
  async mounted () {
    await Promise.all([
      accountFactory.get(),
    ]).then(results => {
      this.accounts = results[0].data.accounts
      this.account_types = results[0].data.account_types
    })
  },
  computed: {
    filteredList() {
      if (this.accounts.length > 0) {
        return this.accounts.filter((account:any) => {
          return account.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return []
    }
  }
})

export default class Account extends Vue {}
